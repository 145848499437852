import React from "react";

const Header2 = ({ children, className }) => (
    <h2
        className={`${className} text-4xl lg:text-5xl font-normal font-cherry-bomb text-outragous-orange leading-10`}
    >
        {children}
    </h2>
);

export default Header2;
